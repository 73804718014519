<template>
  <div class="listing--form-container">
    <facilities-section ref="facilitiesSection" />
    <other-facilities-section ref="otherFacilitiesSection" />
    <operational-hour-section ref="operationalHourSection" />
    <div class="bottom--container">
      <button class="btn btn-primary--outline bottom--button" @click="back">
        {{ $t('addListing.btn.back') }}
      </button>
      <button class="btn btn-primary--outline bottom--button" @click="saveDraft">
        {{ isPublished ? $t('addListing.btn.save') : $t('addListing.btn.saveToDraft') }}
      </button>
      <button v-show="!isPublished" class="btn btn-primary bottom--button" @click="publish">
        {{ $t('addListing.btn.publish') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';

const FacilitiesSection = () =>
  import('@/components/listing-form/sect2/facility/facilities-section.vue');

const OtherFacilitiesSection = () =>
  import('@/components/listing-form/sect2/other-facilities/other-faciltiies-section.vue');

const OperationalHourSection = () =>
  import('@/components/listing-form/sect2/operational-hour/operational-hour-section.vue');
export default {
  name: 'sect2-listing-form',
  mixins: [HelperMixin],
  components: {
    FacilitiesSection,
    OtherFacilitiesSection,
    OperationalHourSection,
  },
  data() {
    return {
      checkBankAccountDone: false,
    };
  },

  // validators: {
  //   dummyData(value) {
  //     return Validator.value(value)
  //       .required(this.$i18n.t('errors.loginRegister.password.required'))
  //       .minLength(8, this.$i18n.t('errors.loginRegister.password.min', { maxChar: 8 }))
  //       .maxLength(255, this.$i18n.t('errors.loginRegister.password.max', { maxChar: 255 }));
  //   },
  // },
  computed: {
    ...mapState({
      sect2Touched: (state) => state.v2.listingForm.sect2.isTouched,
      isPublished: (state) => state.v2.listingForm.isPublished,
    }),
    currentStep: {
      get() {
        return this.$store.state.v2.listingForm.formCurrentStep;
      },
      set(value) {
        this.$store.commit('v2/listingForm/SET_FORM_CURRENT_STEP', value);
      },
    },
  },
  watch: {
    checkBankAccountDone(val) {
      if (val) {
        this.publish();
      }
    },
  },
  methods: {
    async saveDraft() {
      // if (this.isPublished) {
      //   console.log('masuk sini dong');
      //   const success = await this.validateThenPost('detailForm');
      //   if (success) {
      //     this.$store.commit('v2/listingForm/OPEN_COMPLETE_FORM_MODAL', 'SAVE');
      //     this.$modal.show('form_complete_modal');
      //   }
      // } else {
      try {
        this.setLoading(true);

        const postDataSuccess = await this.$store.dispatch('v2/listingForm/saveDraft');
        if (postDataSuccess) {
          this.$store.commit('v2/listingForm/OPEN_COMPLETE_FORM_MODAL', 'SAVE');
          this.$modal.show('modal-success-listing-form');
        }
      } finally {
        this.setLoading(false);
      }
      // }
    },
    validate() {
      let validationArray = [];
      validationArray.push(this.$refs.facilitiesSection.validate());
      validationArray.push(this.$refs.otherFacilitiesSection.validate());
      return Promise.all(validationArray).then(function (results) {
        if (
          results.filter(function (result) {
            console.log('mana yg salah', result);
            return !result;
          }).length === 0
        ) {
          console.log('trueeeee');
          return true;
        }
        console.log('falseeee');
        return false;
      });
    },
    async postPublish() {
      try {
        this.setLoading(true);
        this.$store.commit('v2/listingForm/SET_IS_PUBLISHED', true);
        const postDataSuccess = await this.$store.dispatch('v2/listingForm/postData');
        if (postDataSuccess) {
          this.$store.commit('v2/listingForm/OPEN_COMPLETE_FORM_MODAL', 'PUBLISH');
          this.$modal.show('modal-success-listing-form');
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        this.setLoading(false);
      }
    },
    async publish() {
      try {
        this.setLoading(true);
        const isValid = await this.validate();
        if (isValid) {
          if (!this.checkBankAccountDone) {
            const resultCheck = await this.$store.dispatch('v2/profile/checkUserCanAcceptBooking');
            if (!resultCheck) {
              this.$store.commit('v2/listingForm/OPEN_COMPLETE_FORM_MODAL', 'PUBLISH');
              this.$modal.show('modal-input-bank-account', { postDoneAction: this.publish });
              return false;
            }
          }
          const resultCheckConfirmTc = await this.$store.dispatch(
            'v2/profile/checkUserConfirmServiceFeeTc',
          );
          console.log('resultCheckConfirmTc', resultCheckConfirmTc);
          if (!resultCheckConfirmTc) {
            this.$modal.show('modal-confirm-service-fee-tc', { postDoneAction: this.postPublish });
            return false;
          }
          await this.postPublish();
          return true;
        } else {
          this.scrollToErrorSection(this.$el);
          return false;
        }
      } finally {
        this.setLoading(false);
      }
    },
    setLoading(value) {
      this.$store.commit('v2/listingForm/SET_LOADING', value);
    },
    async back() {
      const postDataSuccess = await this.$store.dispatch('v2/listingForm/saveDraft');
      if (postDataSuccess) {
        this.currentStep = this.currentStep - 1;
      }
      // if (await this.save()) {
      //   this.currentStep = this.currentStep - 1;
      // }
    },
  },
};
</script>

<style scoped></style>
